import React from "react";
import { useGetTicketsQuery } from "../tickets/ticketsApiSlice";
import useAuth from "../../hooks/useAuth"
import {Link } from "react-router-dom"




const Welcome = () => {

  const { firstName, lastName, role, isAdmin, isAgent, email } = useAuth();

  const {data:tickets,isLoading,isSuccess,isError,error} = useGetTicketsQuery();
  
  let content;

  if(isLoading)  content = <p>Loading Tickets...</p>;
  if (isError)   content = <p>{error?.data?.message}</p>;
  
  if(isSuccess){
    const {ids,entities}= tickets;

    //  console.log("entities",entities)
     //Loop though entities and get the status of new tickets and return how many ticket are new
     const newTicketsNumber = Object.values(entities).filter(
       (entity) => {return entity.ticketStatus === "New" && entity.isHiddenTicket===false}
     ).length;
      //Loop though entities and get the status of in progress tickets and return how many ticket are in progress
      const processingTicketsNumber = Object.values(entities).filter(
        (entity) => {return (
          entity.ticketStatus === "Processing" &&
          entity.isHiddenTicket === false
        );}
      ).length;
      //Loop though entities and get the status of approved tickets and return how many ticket are approved
      //Loop though entities and get the status of rejected tickets and return how many ticket are rejected

      const rejectedTicketsNumber = Object.values(entities).filter(
        (entity) => {return entity.ticketStatus === "Rejected"&&entity.isHiddenTicket===false}
      ).length;
      //  console.log("new number", newTicketsNumber);
      const approvedTicketsNumber = Object.values(entities).filter(
        (entity) => {return entity.ticketStatus === "Approved" && entity.isHiddenTicket === false;}
      ).length;
      const RefundedTicketsNumber=Object.values(entities).filter(
        (entity) => {return (
          entity.ticketStatus === "Refund Submitted" &&
          entity.isHiddenTicket === false
        );}
      ).length;
       content = (
         <>
           <div className="relative bg-white">
             <img
               className="h-56 w-full bg-gray-50 object-cover lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-1/2"
               src="https://images.samsung.com/is/image/samsung/assets/sg/price-promise/dashboard.jpg"
               alt=""
             />
             <div className="mx-auto grid max-w-7xl lg:grid-cols-2">
               <div className="px-6 pb-24 pt-16 sm:pb-32 sm:pt-20 lg:col-start-2 lg:px-8 lg:pt-32">
                 <div className="mx-auto max-w-2xl lg:mr-0 lg:max-w-lg">
                   <h2 className="text-base font-semibold leading-8 text-indigo-600">
                     Welcome {firstName} {lastName} - {role}
                   </h2>
                   {/* <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                     You can mananger price promise ticket here.
                   </p> */}

                   <div className="mt-6">
                     {role === "Admin" || isAdmin ? (
                       <Link
                         to="/register"
                         // className="text-sm font-semibold leading-6 text-gray-900"
                         className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                       >
                         Create a user account <span aria-hidden="true">→</span>
                       </Link>
                     ) : (
                       ""
                     )}
                   </div>

                   <dl className="mt-16 grid max-w-xl grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 xl:mt-16">
                     <div
                       // key={stat.id}
                       className="flex flex-col gap-y-3 border-l border-gray-900/10 pl-6"
                     >
                       <dt className="text-sm leading-6 text-gray-600">
                         New tickets
                       </dt>
                       <dd className="order-first text-3xl font-bold tracking-tight text-gray-900">
                         {newTicketsNumber}
                       </dd>
                     </div>
                     <div
                       // key={stat.id}
                       className="flex flex-col gap-y-3 border-l border-gray-900/10 pl-6"
                     >
                       <dt className="text-sm leading-6 text-gray-600">
                         in progress
                       </dt>
                       <dd className="order-first text-3xl font-bold tracking-tight text-yellow-700">
                         {processingTicketsNumber}
                       </dd>
                     </div>
                     <div
                       // key={stat.id}
                       className="flex flex-col gap-y-3 border-l border-gray-900/10 pl-6"
                     >
                       <dt className="text-sm leading-6 text-gray-600">
                         Approved
                       </dt>
                       <dd className="order-first text-3xl font-bold tracking-tight text-green-700">
                         {approvedTicketsNumber}
                       </dd>
                     </div>
                     <div
                       // key={stat.id}
                       className="flex flex-col gap-y-3 border-l border-gray-900/10 pl-6"
                     >
                       <dt className="text-sm leading-6 text-gray-600">
                         Rejected
                       </dt>
                       <dd className="order-first text-3xl font-bold tracking-tight text-red-700">
                         {rejectedTicketsNumber}
                       </dd>
                     </div>
                     <div
                       // key={stat.id}
                       className="flex flex-col gap-y-3 border-l border-gray-900/10 pl-6"
                     >
                       <dt className="text-sm leading-6 text-gray-600">
                         Refund Submitted
                       </dt>
                       <dd className="order-first text-3xl font-bold tracking-tight text-green-700">
                         {RefundedTicketsNumber}
                       </dd>
                     </div>
                   </dl>
                 </div>
               </div>
             </div>
           </div>
         </>
       );
    }
  

  return content
};

export default Welcome;


