import { useState,useEffect } from "react";
import { useUpdateTicketMutation } from "./ticketsApiSlice";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import useAuth from "../../hooks/useAuth";

export default function EditTicketForm({ ticket }) {
  const { ticketId } = ticket;

  //user information
  const { firstName, lastName, role, isAdmin, isAgent } = useAuth();
  const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  // console.log("first name is", firstName);
  // console.log("last name is", lastName);

  //status Options end
  const navigate = useNavigate();

  const [updateTicket, { isLoading, isSuccess, isError, error }] =
    useUpdateTicketMutation();

  const [email, setEmail] = useState(ticket.email);
  const [validEmail, setValidEmail] = useState(false);

  const [approvalComment, setApprovalComment] = useState(
    ticket.approvalComment
  );

  const [lastUpdatedPerson, setLastUpdatedPerson] = useState(
    ticket.lastUpdatedPerson
  );
  //Reject comment choose

  const [rejectComment, setRejectComment] = useState(ticket.rejectComment);
  const [ticketStatus, setTicketStatus] = useState(ticket.ticketStatus);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(ticket.email));
  }, [email]);

  const validEmailClass = !validEmail ? <p>Please enter validate Email</p> : "";
 

  const handleCancelNavigate = () => {
    window.location.reload();
  };

  const onTicketStatusChanged = (e) => {
    setTicketStatus(e.target.value);
    setLastUpdatedPerson(firstName + " " + lastName);
  };
  const onEmailChanged = (e) => setEmail(e.target.value);

  const onRejectCommentChanged = (e) => {
    setRejectComment(e.target.value);
    setApprovalComment(null);
  };
  // const onApprovalCommentChanged = (e) => {setApprovalComment(e.target.value);setRejectComment(null);}
  // const onLastUpdatedPersonChanged = () => setLastUpdatedPerson(firstName+lastName);
  // console.log("lastUpdatedPerson",lastUpdatedPerson);

  if (isSuccess) {
    toast("Data Updated Successfully!");
    setTimeout(() => {
      navigate(`/dashboard/tickets`);
    }, 1500);
  }

  if (isError) {
    toast("Data Update fail!", error);
    setTimeout(() => {
      navigate(`/dashboard/tickets`);
    }, 1500);
  }

  const setTicketStatusContent = (
    <>
      {ticketStatus === "Processing" ? (
        <button
          onClick={onTicketStatusChanged}
          value="Processing"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 mx-0.5 rounded"
        >
          Processing
        </button>
      ) : (
        <button
          onClick={onTicketStatusChanged}
          value="Processing"
          className="bg-transparent hover:bg-blue-500 text-blue-700 font-bold py-2 px-4 border border-blue-700 mx-0.5 rounded"
        >
          Processing
        </button>
      )}

      {ticketStatus === "Rejected" ? (
        <button
          onClick={onTicketStatusChanged}
          value="Rejected"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 mx-0.5 rounded"
        >
          Rejected
        </button>
      ) : (
        <button
          onClick={onTicketStatusChanged}
          value="Rejected"
          className="bg-transparent hover:bg-blue-500 text-blue-700 font-bold py-2 px-4 border border-blue-700 mx-0.5 rounded"
        >
          Rejected
        </button>
      )}

      {ticketStatus === "Approved" ? (
        <button
          onClick={onTicketStatusChanged}
          value="Approved"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 mx-0.5 rounded"
        >
          Approved
        </button>
      ) : (
        <button
          onClick={onTicketStatusChanged}
          value="Approved"
          className="bg-transparent hover:bg-blue-500 text-blue-700 font-bold py-2 px-4 border border-blue-700 mx-0.5 rounded"
        >
          Approved
        </button>
      )}
      {ticketStatus === "Refund Submitted" ? (
        <button
          onClick={onTicketStatusChanged}
          value="Refund Submitted"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 mx-0.5 rounded"
        >
          Refund Submitted
        </button>
      ) : (
        <button
          onClick={onTicketStatusChanged}
          value="Refund Submitted"
          className="bg-transparent hover:bg-blue-500 text-blue-700 font-bold py-2 px-4 border border-blue-700 mx-0.5 rounded"
        >
          Refund Submitted
        </button>
      )}
    </>
  );

  const setCommentContent = (
    <>
      {ticketStatus === "Rejected" ? (
        <fieldset>
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:py-6">
            <div
              className="text-sm font-semibold leading-6 text-gray-900"
              aria-hidden="true"
            >
              Rejected Comment
            </div>
            <div className="mt-4 sm:col-span-2 sm:mt-0">
              <div className="max-w-lg space-y-6">
                <>
                  {rejectComment ===
                  "We consider your claim to have insufficient evidence of the product details (e.g. product ID, URL, Retailer details, price) in the images provided of the retailer’s website." ? (
                    <button
                      onClick={onRejectCommentChanged}
                      value="We consider your claim to have insufficient evidence of the product details (e.g. product ID, URL, Retailer details, price) in the images provided of the retailer’s website."
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 mx-0.5 rounded"
                    >
                      We consider your claim to have insufficient evidence of
                      the product details (e.g. product ID, URL, Retailer
                      details, price) in the images provided of the retailer’s
                      website.
                    </button>
                  ) : (
                    <button
                      onClick={onRejectCommentChanged}
                      value="We consider your claim to have insufficient evidence of the product details (e.g. product ID, URL, Retailer details, price) in the images provided of the retailer’s website."
                      className="bg-transparent hover:bg-blue-500 text-blue-700 font-bold py-2 px-4 border border-blue-700 mx-0.5 rounded"
                    >
                      We consider your claim to have insufficient evidence of
                      the product details (e.g. product ID, URL, Retailer
                      details, price) in the images provided of the retailer’s
                      website
                    </button>
                  )}

                  {rejectComment ===
                  "We could not confirm the price on the retailer’s website for the product provided." ? (
                    <>
                      <button
                        onClick={onRejectCommentChanged}
                        value="We could not confirm the price on the retailer’s website for the product provided."
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 mx-0.5 rounded"
                      >
                        We could not confirm the price on the retailer’s website
                        for the product provided.
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={onRejectCommentChanged}
                      value="We could not confirm the price on the retailer’s website for the product provided."
                      className="bg-transparent hover:bg-blue-500 text-blue-700 font-bold py-2 px-4 border border-blue-700 mx-0.5 rounded"
                    >
                      We could not confirm the price on the retailer’s website
                      for the product provided.
                    </button>
                  )}

                  {rejectComment ===
                  "We believe the price on the website was made in error, and was not fulfilled by the retailer." ? (
                    <>
                      <button
                        onClick={onRejectCommentChanged}
                        value="We believe the price on the website was made in error, and was not fulfilled by the retailer."
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 mx-0.5 rounded"
                      >
                        We believe the price on the website was made in error,
                        and was not fulfilled by the retailer.
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={onRejectCommentChanged}
                      value="We believe the price on the website was made in error, and was not fulfilled by the retailer."
                      className="bg-transparent hover:bg-blue-500 text-blue-700 font-bold py-2 px-4 border border-blue-700 mx-0.5 rounded"
                    >
                      We believe the price on the website was made in error, and
                      was not fulfilled by the retailer.
                    </button>
                  )}

                  {rejectComment ===
                  "The product or retailer is not eligible for the Price Promotion. For a full list of eligible retailers, please visit: https://www.samsung.com/sg/offer/price-promise/#eligibility" ? (
                    <>
                      <button
                        onClick={onRejectCommentChanged}
                        value="The product or retailer is not eligible for the Price Promotion. For a full list of eligible retailers, please visit: https://www.samsung.com/sg/offer/price-promise/#eligibility"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 mx-0.5 rounded"
                      >
                        The product or retailer is not eligible for the Price
                        Promotion. For a full list of eligible retailers, please
                        visit: https://www.samsung.com/sg/offer/price-promise/#eligibility
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={onRejectCommentChanged}
                      value="The product or retailer is not eligible for the Price Promotion. For a full list of eligible retailers, please visit: https://www.samsung.com/sg/offer/price-promise/#eligibility"
                      className="bg-transparent hover:bg-blue-500 text-blue-700 font-bold py-2 px-4 border border-blue-700 mx-0.5 rounded"
                    >
                      The product or retailer is not eligible for the Price
                      Promotion. For a full list of eligible retailers, please
                      visit: https://www.samsung.com/sg/offer/price-promise/#eligibility
                    </button>
                  )}

                  {rejectComment ===
                  "We have deemed your purchase to be ineligible as the purchase date is outside our 14-day Price Promise claim period." ? (
                    <>
                      <button
                        onClick={onRejectCommentChanged}
                        value="We have deemed your purchase to be ineligible as the purchase date is outside our 14-day Price Promise claim period."
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 mx-0.5 rounded"
                      >
                        We have deemed your purchase to be ineligible as the
                        purchase date is outside our 14-day Price Promise claim
                        period.
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={onRejectCommentChanged}
                      value="We have deemed your purchase to be ineligible as the purchase date is outside our 14-day Price Promise claim period."
                      className="bg-transparent hover:bg-blue-500 text-blue-700 font-bold py-2 px-4 border border-blue-700 mx-0.5 rounded"
                    >
                      We have deemed your purchase to be ineligible as the
                      purchase date is outside our 14-day Price Promise claim
                      period.
                    </button>
                  )}
                </>
              </div>
            </div>
          </div>
        </fieldset>
      ) : (
        <></>
      )}

      {/* {ticketStatus === "Approved" ? (
        <fieldset>
          <legend className="sr-only">Change Ticket Status</legend>
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:py-6">
            <div
              className="text-sm font-semibold leading-6 text-gray-900"
              aria-hidden="true"
            >
              Approved Comment
            </div>
            <div className="mt-4 sm:col-span-2 sm:mt-0">
              <div className="max-w-lg space-y-6">
                <>
                  {approvalComment ===
                  "Approval 1 Lorem ipsum dolor sit amet" ? (
                    <button
                      onClick={onApprovalCommentChanged}
                      value="Approval 1 Lorem ipsum dolor sit amet"
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 mx-0.5 rounded"
                    >
                      Approval 1 Lorem ipsum dolor sit amet
                    </button>
                  ) : (
                    <button
                      onClick={onApprovalCommentChanged}
                      value="Approval 1 Lorem ipsum dolor sit amet"
                      className="bg-transparent hover:bg-blue-500 text-blue-700 font-bold py-2 px-4 border border-blue-700 mx-0.5 rounded"
                    >
                      Approval 1 Lorem ipsum dolor sit amet
                    </button>
                  )}

                  {approvalComment ===
                  "Approval 2 consectetur adipiscing elit" ? (
                    <>
                      <button
                        onClick={onApprovalCommentChanged}
                        value="Approval 2 consectetur adipiscing elit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 mx-0.5 rounded"
                      >
                        Approval 2 consectetur adipiscing elit
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={onApprovalCommentChanged}
                      value="Approval 2 consectetur adipiscing elit"
                      className="bg-transparent hover:bg-blue-500 text-blue-700 font-bold py-2 px-4 border border-blue-700 mx-0.5 rounded"
                    >
                      Approval 2 consectetur adipiscing elit
                    </button>
                  )}

                  {approvalComment === "Approval 3 sed do eiusmod tempor" ? (
                    <>
                      <button
                        onClick={onApprovalCommentChanged}
                        value="Approval 3 sed do eiusmod tempor"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 mx-0.5 rounded"
                      >
                        Approval 3 sed do eiusmod tempor
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={onApprovalCommentChanged}
                      value="Approval 3 sed do eiusmod tempor"
                      className="bg-transparent hover:bg-blue-500 text-blue-700 font-bold py-2 px-4 border border-blue-700 mx-0.5 rounded"
                    >
                      Approval 3 sed do eiusmod tempor
                    </button>
                  )}
                </>
              </div>
            </div>
          </div>
        </fieldset>
      ) : (
        <> </>
      )} */}
    </>
  );

  // console.log("email", email);
  // console.log("ticketStatus", ticketStatus);
  // console.log("rejectComment", rejectComment);

  const onSaveTicketClicked = async (e) => {
    await updateTicket({
      ticketId: ticketId,
      email: email,
      ticketStatus: ticketStatus,
      lastUpdatedPerson: lastUpdatedPerson,
      rejectComment: rejectComment,
    });
  };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <ToastContainer
          position="top-center"
          autoClose={1000}
          limit={1}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover
          theme="light"
        />
        <div className="space-y-12 sm:space-y-16">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Ticket Information
            </h2>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
              Edit Ticket
            </p>

            <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="ticket-id"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Ticket ID
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  {ticket.ticketId}
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Ticket Currently Status
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  {ticket.ticketStatus === "Rejected" ? (
                    <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                      {ticketStatus.toUpperCase()}
                    </span>
                  ) : (
                    <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                      {ticketStatus.toUpperCase()}
                    </span>
                  )}
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Multiple Claim Flag
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  {ticket.multipleClaimFlag === "yes" ? (
                    <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                      {ticket.multipleClaimFlag.toUpperCase()}
                    </span>
                  ) : (
                    <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                      {ticket.multipleClaimFlag.toUpperCase()}
                    </span>
                  )}
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="ticket-id"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Reject Comment
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  {rejectComment === null ? (
                    <span>No Comment</span>
                  ) : (
                    <span>{rejectComment}</span>
                  )}
                </div>
              </div>

              {/* <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="ticket-id"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Approval Comment
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  {approvalComment === null ? (
                    <span>No Comment</span>
                  ) : (
                    <span>{approvalComment}</span>
                  )}
                </div>
              </div> */}

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Email address
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    onChange={onEmailChanged}
                    defaultValue={ticket.email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6"
                  />
                </div>
                {validEmailClass}
              </div>
            </div>
          </div>

          <div>
            <div className="mt-10 space-y-10 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
              <fieldset>
                <legend className="sr-only">Change Ticket Status</legend>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:py-6">
                  <div
                    className="text-sm font-semibold leading-6 text-gray-900"
                    aria-hidden="true"
                  >
                    Status Setting
                  </div>
                  <div className="mt-4 sm:col-span-2 sm:mt-0">
                    <div className="max-w-lg space-y-6">
                      {setTicketStatusContent}
                    </div>
                  </div>
                </div>
              </fieldset>
              {setCommentContent}
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            onClick={handleCancelNavigate}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>
          <button
            type="submit"
            onClick={onSaveTicketClicked}
            className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Save
          </button>
        </div>
      </form>
    </>
  );
}
